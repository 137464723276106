import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Team = (props) => {
  const teams = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-aboutus">
      <SEO title="About Us" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>About Us</h1>
              <p>
                We're a team of passionate individuals that pride ourselves in delivering quality outcomes.
                Our focus is on developing and maintaining healthy business relationships that result in wins for all.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/team/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            image
            
          }
        }
      }
    }
  }
`;

export default Team;
